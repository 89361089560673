.listicle {
	padding: 20px 0 200px 0;

	.container {
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

	  h1 {
	    font-size: 2.8em;
    	line-height: 1.2em;
    	font-weight: 700;
	  }
	  h2 {
	    font-size: 2.4em;
      margin: 20px 0;  
	  }        
	  .sub-title {
	    font-size: 1.6em;
	    font-weight: 400;
	    p {
	    	color: #f30000;
            font-size: 1.6rem;
            margin: 18px 0;
	    }

	    a {
	      color: blue;
	      text-decoration: underline;
	    }
	  }
    p {
        font-size: 1.4em;
        line-height: 1.4em;
    } 
    img {
        width: 100%
    }
	  .date {
	    margin-bottom: 5px;
      font-size: 1rem;
	  }
	  .markdown-content {
	    h2, h3, h4, h5 {
	      font-weight: 700;
	    }

	    a {
	      color: blue;
	    }

	    blockquote {
	    	background: #ecffc6;
	    	padding: 18px 25px;
	    	margin: 0;
	    	font-style: italic;

	      &:before {
	        color: #ccc;
					content: open-quote;
					font-size: 4em;
					line-height: 0.1em;
					margin-right: 0.25em;
					vertical-align: -0.4em;
	      }
	    }
	    .highlight {
	      background: #f4fd23;
	    }
	    ul {
	    	list-style: none;
	    	padding-left: 0;
        font-size: 1.4em;

	    	li {
	    		display: flex;
    			align-items: flex-start;
          margin: 18px 0;

	    		&:before {
						color: transparent;
						content: " ";
						font-size: 0;
						padding: 10px;
						margin-top: 6px;
						margin-right: 10px;
						background-color: #6569ef;
						-webkit-mask-image: url(../images/check-circle-solid.svg);
						-webkit-mask-size: cover;
	    		}
	    	}
	    }
	  }

	  .content-section {
	    h2 {
	      font-size: 30px;
	      line-height: 1.2;
	      font-weight: 600;
	    }
	    .markdown-content {
	      br {
	        display: block;
	        margin: 10px 0;
	        line-height: 16px;
	        content: " ";
	      }
	    }
	  }

	  .discount-box {
			background: #ecffc6;
			border-radius: 5px;
			border: 3px dashed #333;
			padding: 20px;
			margin: 20px 0;

			h3 {
			  font-size: 1.6em;
		    font-weight: 700;
		    line-height: 1.2em;
		    text-align: left;
		    margin: 0;
		    padding: 14px 0;
			}

			p {
				margin: 0;
			  margin-bottom: 15px;
			}
			a {
			  color: blue;
			}
	  }
	  .cta {
	    width: calc(100% - 56px);
        margin: 30px 0 60px 0;

	    a {
	      display: block;
	      text-align: center;
	    }
	  }
	}
	

	@media(max-width: 767px) {
	  .container {
	    h1 {
	      font-size: 32px;
	    }
	  }
	}
}

header.landerListicle {
    background: #fff;
    border-bottom: 1px solid #efefef;
    
    .container {
        max-width: 800px;
    }
}