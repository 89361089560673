@media #{$below-mid-point} {
	.nav-open {

		nav {
			border: 0;
			z-index: 2;

			a {
				display: block;
			}
		}
	}
}

header {
	color: #fff;
	padding: 6px 0;
	background: linear-gradient(135deg, $brand-color 0%, $brand-color-2 100%);
	position: relative;

	.container {
		display: flex;
		align-items: center;
	}
    
	p {
		margin: 0;
	}

	a {
		color: #fff;
		text-decoration: none;
		z-index: 1;
		position: relative;

		&:hover {
			text-decoration: none;
		}
	}
	.ip-countryflag {
		position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    img {
    	margin-top: auto;
    }
	}
	.mobile-show {
		display: none;
	}
	.mobile-hidden {
		display: block;
	}

	@media(max-width: 849px){
		padding: 5px 0;
		.container {
			flex-wrap: wrap;
		}
		.ip-countryflag {
	    right: 10px;
	    top: 50%;
	    height: 16px;
		}
		.ip-countryflag.mobile-show {
			right: 30px;
			top: 50%;
			height: 16px;
		}
		.mobile-show {
			display: block;
		}
		.mobile-hidden {
			display: none;
		}
	}
}

nav {
	width: 100%;
	padding: 20px 0 0 0;

	.ip-countryflag {
	  position: absolute;
    right: 100px;
	}

	@media #{$mid-point} {
		display: inline-block;
		/* vertical-align: top; */
		width: auto;
		padding: 0 0 0 40px;
	}

	a {
		margin: 0 3px;
		padding: 20px 10px;
		border-bottom: 1px solid rgba(255,255,255,0);
		color: rgba(255,255,255,.6);
		transition: 200ms ease-in color;
		display: none;

		@media #{$mid-point} {
			display: inline-block;
			padding: 10px;
		}


		&.nav-toggle {
			display: inline;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.7em;
			border: 0;
			z-index: 2;
			padding: 20px;

			@media #{$mid-point} {
				display: none;
			}

			&:hover {
				border: 0;
			}
		}
	}

	a:hover {
		color: #fff;
	}


	a.cart {
		line-height: 1;

		i {
			font-size: 18px;
			line-height: 1;
			display: inline-block;
			vertical-align: middle;
			padding: 0 6px;
		}

		span {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: 2px;

		}
	}

	@media #{$mid-point} {
		a.cart {
			border-radius: 43px;
			color: #fff;
			border: 1px solid rgba(255,255,255, .4);
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			font-size: .8em;
			transition: border .3s ease;


			&:hover {
				border-color: #fff;
			}
		}
	}

	a.active {
		color: #fff;
	}
	@media(max-width: 849px){
		padding-top: 0;
		.mobile-header {
			position: relative;

			a {
				padding: 10px 0;
			}
		}
	}
}


