$brand-color: #202020;
$brand-color-2: #383838;
$default-hero-image: "/images/hero/box-top.jpg";
$brand-button-color: #ff1f1f;

// Breakpoints
$tablet: "(min-width: 450px)";
$above-tablet: "(min-width: 550px)";
$mid-point: "(min-width: 850px)";
$below-mid-point: "(max-width: 849px)";
$desktop: "(min-width: 768px)";