html, body {
	margin: 0;
	padding: 0;
}

body {
	font-family: "San Francisco", "Helvetica Neue", "Helvetica", "Arial";
	-webkit-font-smoothing: antialiased;
	color: #333;
}

a {
	color: $brand-color;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

img {
	max-width: 100%;
}

h1 strong, h2 strong {
	font-weight: 700;
}

h1 {
	font-weight: 300;
	font-size: 2.3em;
	margin: 0;
}

h2 {
	font-weight: 300;
	font-size: 2.2em;
	margin: 0;
}

h3 {
	font-size: 2rem;
	margin: 20px 0 10px 0;
}

h4 {
	font-size: 1.4rem;

	a {
		color: #000;
	}
}

p, address, label, ul {
	font-size: 1.2rem;
	color: inherit;
	margin-bottom: 20px;
	line-height: 1.4em;
}

ul {
	padding-left: 1em;
}

.mb-2 {
	margin-bottom: 2rem;
}
