.pull-right{
  float:right;
}
.pull-left{
  float:left;
  display: flex;

  input {
    width: 20px;
    margin-top: 3px;
  }
  label {
    width: 200px;
    margin-top: 0;
  }
}
#fbcomment{
  background:#fff;
  border: 1px solid #dddfe2;
  border-radius: 3px;
  color: #4b4f56;
  padding:50px;
}
.header_comment{
  font-size: 14px;
  overflow: hidden;
  border-bottom: 1px solid #e9ebee;
  line-height: 25px;
  margin-bottom: 24px;
  padding: 10px 0;

  .row {
    display: flex;
    justify-content: space-between;
  }
}
.sort_title{
  color: #4b4f56;
}
.sort_by{
  background-color: #f5f6f7;
  color: #4b4f56;
  line-height: 22px;
  cursor: pointer;
  vertical-align: top;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
  padding: 4px;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid #ccd0d5;
}
.count_comment{
  font-weight: 600;
}
.body_comment{
  padding: 0 8px;
  font-size: 14px;
  display: block;
  line-height: 25px;
  word-break: break-word;

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
.avatar_comment{
  display: block;
  width: 60px;
}
.avatar_comment img{
  height: 48px;
  width: 48px !important;
}
.box_comment{
  display: block;
  position: relative;
  width: calc(100% - 60px);
  line-height: 1.358;
  word-break: break-word;
  border: 1px solid #d3d6db;
  word-wrap: break-word;
  background: #fff;
  box-sizing: border-box;
  cursor: text;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 0;
}
.box_comment textarea{
	min-height: 40px;
	padding: 12px 8px;
	width: 100%;
	border: none;
	resize: none;
}
.box_comment textarea:focus{
  outline: none !important;
}
.box_comment .box_post{
	border-top: 1px solid #d3d6db;
    background: #f5f6f7;
    padding: 8px;
    display: block;
    overflow: hidden;
}
.box_comment label{
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
  color: #90949c;
  line-height: 22px;
}
.box_comment button{
  margin-left:8px;
  background-color: #4267b2;
  border: 1px solid #4267b2;
  color: #fff;
  text-decoration: none;
  line-height: 22px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  text-align: center;
}
.box_comment button:hover{
  background-color: #29487d;
  border-color: #29487d;
}
.box_comment .cancel{
	margin-left:8px;
	background-color: #f5f6f7;
	color: #4b4f56;
	text-decoration: none;
	line-height: 22px;
	border-radius: 2px;
	font-size: 14px;
	font-weight: bold;
	position: relative;
	text-align: center;
  border-color: #ccd0d5;
}
.box_comment .cancel:hover{
	background-color: #d0d0d0;
	border-color: #ccd0d5;
}
.box_comment img{
  height:16px;
  width:16px;
}
.box_result{
  margin-top: 24px;
}
.box_result .result_comment h4{
  font-weight: 600;
  white-space: nowrap;
  color: #365899;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.358;
  margin:0;
  padding-top: 0;
}
.box_result .result_comment{
  display:block;
  overflow:hidden;
  width: calc(100% - 60px);
  padding: 0;
}
.child_replay{
	border-left: 1px dotted #d3d6db;
	margin-top: 12px;
	list-style: none;
	padding:0 0 0 8px
}
.reply_comment{
	margin:12px 0;
  width: 100%;
}
.box_result .result_comment p{
  margin: 4px 0;
  font-size: 16px;
}
.box_result .result_comment .tools_comment{
  font-size: 12px;
  line-height: 1.358;
}
.box_result .result_comment .tools_comment a{
  color: #4267b2;
  cursor: pointer;
  text-decoration: none;
}
.box_result .result_comment .tools_comment span{
  color: #90949c;
}
.body_comment .show_more{
  background: #3578e5;
  border: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  margin-top: 24px;
  padding: 12px;
  text-shadow: none;
  width: 100%;
  font-weight:bold;
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
  cursor: pointer;
}

.list-comment {
  li {
    &:before {
      display: none;
    }
  }
  .box_result {
    display: flex;
    align-items: flex-start;
  }
}

@media(max-width: 480px) {
  #fbcomment{
    padding: 30px 10px;
  }
}