.rocking {
    animation: rocking 2s infinite;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    transition: .2s;
}

.wiggle {
  animation: wiggle 3s infinite;
}

.wiggle:hover {
  animation: none;
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(2deg); }
   95% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
}

@keyframes wobble {
  16.65% { transform: translateX(8px); }
  33.3% { transform: translateX(-6px); }
  49.95% { transform: translateX(4px); }
  66.6% { transform: translateX(-2px); }
  83.25% { transform: translateX(1px); }
  100% { transform: translateX(0); }
}

@keyframes rocking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(0deg); }
    50% { transform: rotate(2deg); }
    75% { transform: rotate(-2deg); }
    100% { transform: rotate(0deg); }
}

@keyframes rotate {
  0% { transform: rotate(0) skew(1deg); }
  10% { transform: rotate(0) skew(1deg); }
  20% { transform: rotate(0) skew(1deg); }
  30% { transform: rotate(-1deg) skew(1deg); }
  31% { transform: rotate(1deg) skew(1deg); }
  40% { transform: rotate(0) skew(1deg); }
  50% { transform: rotate(0) skew(1deg); }
  60% { transform: rotate(0) skew(1deg); }
  70% { transform: rotate(0) skew(1deg); }
  80% { transform: rotate(0) skew(1deg); }
  90% { transform: rotate(0) skew(1deg); }
  100% { transform: rotate(0) skew(1deg); }
}

@keyframes xyTransition {
  0% { transform: translateX(0);}
  5% { transform: translateX(-10px);}
  10% { transform: translateX(10px);}
  15% { transform: translateX(-8px);}
  20% { transform: translateX(8px);}
  25% { transform: translateX(-5px);}
  30% { transform: translateX(5px);}
  31% { transform: translateX(0);}
  100% { transform: translateX(0);}
}

@keyframes visible-show {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes visible-hide {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}


@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
  	transform: scale(1);
  }
}

@keyframes slideup {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

