.button a, .button button, input[type=submit] {
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    width: 100%;
    padding: 20px 30px;
    background: $brand-button-color;
    border-radius: 5px;
    border: 0px solid #3a3a3a;
    -webkit-appearance: none;
}

.button a:hover,
.button button:hover,
input[type=submit]:hover {
	background: darken($brand-button-color, 10%);
	cursor: pointer;
}

.button a:active,
.button button:active,
input[type=submit]:active {
	border-width: 1px;
	border-top: 3px solid transparent
}

.button.alt a {
	background: rgba(255,255,255,0.15);
	border-radius: 3px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	padding: 16px 50px;
}

.button.alt a:hover {
	background: #fff;
	color: $brand-color;
}

textarea, input, button, select { font-family: inherit; font-size: inherit; }

input[type=submit] {
	margin: 20px 0 0 0;
}

label, input, textarea, select {
	display: block;
	width: 100%;
	box-sizing: border-box;
	border-radius: 3px;
}

.radio-input {
	line-height: 1;
	margin: 20px 0;
	cursor: pointer;

	input {
		display: inline-block;
		width: auto;
		cursor: pointer;
	}
}

textarea {
	resize: vertical;
	height: 150px;
}

label {
	margin: 20px 0 5px 0;
}

label:first-child {
	margin: 0 0 5px 0;
}

label:last-child, input:last-child {
	margin-bottom: 0;
}

input, textarea, select {
	padding: 10px;
	font-size: 1em;
}

input, textarea {
	outline: none;
	border: 1px solid #DDDDDD;
}

input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, textarea:focus {
	box-shadow: 0 0 5px $brand-color;
	border: 1px solid $brand-color;
}
