@keyframes scaleInOut {
  0% { transform: scale(1); }
  30% { transform: scale(1.05); }
  50% { transform: scale(1.1); }
  70% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.lander {
	padding: 20px 0 200px 0;

	.container {
		display: flex;
		flex-wrap: wrap;
        max-width: 1080px;
	}

	.left-content {
	  width: calc(100% - 350px);
	  margin-right: 50px;

	  h1 {
	    font-size: 2.8em;
    	line-height: 1.2em;
    	font-weight: 700;

    	p {
    	  font-size: inherit;
    	  line-height: inherit;
    	  font-weight: inherit;
    	  color: inherit;
          margin: 15px 0;     
    	}

    	* {
    	  font-size: inherit;
    	  line-height: inherit;
    	  font-weight: inherit;
    	  color: inherit;
    	}
	  }
	  h2 {
	    font-size: 2.4em;
      margin: 20px 0;

      p {
    	  font-size: inherit;
    	  line-height: inherit;
    	  font-weight: inherit;
    	  color: inherit;
    	}
    	
    	* {
    	  font-size: inherit;
    	  line-height: inherit;
    	  font-weight: inherit;
    	  color: inherit;
    	}  
	  }        
	  .sub-title {
	    font-size: 1.6em;
	    font-weight: 400;
	    p {
	    	color: #f30000;
        font-size: 1.6rem;
	    }

	    a {
	      color: blue;
	      text-decoration: underline;
	    }
	  }
      p {
          font-size: 1.4em;
          line-height: 1.4em;
      } 
      img {
          width: 100%
      }
	  .date {
	    margin-bottom: 5px;
        font-size: 1rem;
	  }
	  .markdown-content {
	    h2, h3, h4, h5 {
	      font-weight: 700;
	    }

	    a {
	      color: blue;
	    }

	    blockquote {
	    	background: #ecffc6;
	    	padding: 18px 25px;
	    	margin: 0;
	    	font-style: italic;

	      &:before {
	        color: #ccc;
					content: open-quote;
					font-size: 4em;
					line-height: 0.1em;
					margin-right: 0.25em;
					vertical-align: -0.4em;
	      }
	    }
	    .highlight {
	      background: #f4fd23;
	    }
	    ul {
	    	list-style: none;
	    	padding-left: 0;
        font-size: 1.4em;

	    	li {
	    		display: flex;
    			align-items: flex-start;
          margin: 18px 0;

	    		&:before {
						color: transparent;
						content: " ";
						font-size: 0;
						padding: 10px;
						margin-top: 6px;
						margin-right: 10px;
						background-color: #6569ef;
						-webkit-mask-image: url(../images/check-circle-solid.svg);
						-webkit-mask-size: cover;
	    		}
	    	}
	    }
	  }

	  .discount-box {
			background: #ecffc6;
			border-radius: 5px;
			border: 3px dashed #333;
			padding: 20px;
			margin: 20px 0;

			h3 {
			  font-size: 1.6em;
		    font-weight: 700;
		    line-height: 1.2em;
		    text-align: left;
		    margin: 0;
		    padding: 14px 0;
			}
			.ip-track {
			  margin-bottom: 10px;
			}

			p {
				margin: 0;
			  margin-bottom: 15px;
			}
			a {
			  color: blue;
			}
	  }
	  .wiggle {
	    width: calc(100% - 56px);
	    margin-bottom: 30px;

	    a {
	      display: block;
	      text-align: center;
	    }
	  }
	  .comments {
	    margin-bottom: 20px;
	  }
	}
	.right-content {
	 	position: relative;
		width: 300px;

		.side-box {
		  position: sticky;
		  position: -webkit-sticky;
		  top: 30px;
		  width: 100%;
		  text-align: center;

		  .ip-track {
		    margin: 20px 0 10px;

		    .ip-countryflag {
		      margin-left: 10px;
		      content: "";
		    }
		  }

		  img {
		    width: 100%;
		  }

		  p {
		    margin: 0px;
		    font-size: 16px;
		    font-weight: 600;
		    line-height: 26px;
		    color: black;
		  }
		  .button {
		    margin-top: 30px;
		  }
		  .style-2 {
        border: 2px solid gray;
        padding: 20px;

        .ip-track {
          margin-top: 0px;
          font-size: 13px;
        }

        h3 {
          color: blue;
          width: 100%;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;
        }
        h4 {
          margin: 5px 0;
        }
        a {
          text-decoration: underline;
          text-transform: uppercase;
          text-align: center;
          width: 100%;
          display: block;
          font-weight: 600;
          color: blue;
        }
      }
		}
	}
	.sticky-bar {
    position: sticky;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    background: rgba(58, 87, 157, 1);
    color: white;

    .flex-wrap {
      justify-content: center;
      align-items: center;

      h3 {
        font-weight: 600;
        margin: 10px 0;
      }

      .button {
        width: 300px;
        background: #e00000;
        padding: 10px 0;
        margin: 0;
        margin-left: 50px;
        border: 1px dashed #fff;
        letter-spacing: 1px;    
        color: white;
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
      }
    }
  }

  .articles {
  	margin: 50px 0;
  }

  .trending-articles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0 1px 15px rgba(171, 174, 209, .3);
    background-color: #fff;
    padding: 10px 20px;
    
    .header {
    	width: 100%;
    	display: flex;
    	align-items: center;

    	img {
    		width: 30px;
    		margin-right: 10px;
    	}

    	h3 {
    		font-size: 24px;
    		margin: 0;
    	}
    }
    .card {
    	width: 31%;
    	box-shadow: 0 1px 15px rgba(171, 174, 209, .3);
    	margin: 10px 0;

    	&:hover {
    		cursor: pointer;
    		animation: 1s alternate scaleInOut;
    	}

    	.image-wrap {
    		width: 100%;

    		img {
	    		object-fit: cover;
			    height: 100%;
			    width: 100%;
	    	}
    	}

    	.text-wrap {
    		position: relative;
    		padding: 5px 10px;
    		.title {
    			font-size: 18px;
    			font-weight: 500;
    			line-height: 1.6;
    			text-align: center;
    			margin: 0;
    			height: 60px;
    			overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
    		}
    		.author {
    			font-size: 14px;
    			line-height: 1.6;
    		  color: #484848;
			    text-align: left;
			    font-style: italic;
			    margin: 0;
    		}
    	}
    }
  }
  .trending-articles-pop {
	  position: fixed;
	  visibility: hidden;
	  z-index: 100;
	  width: 100%;
	  height: 100%;
	  top: 0;

	  .close {
	  	position: absolute;
	  	z-index: 3;
	  	top: 30px;
	  	right: 30px;

	  	&:hover {
	  		cursor: pointer;
	  	}
	  }

	  &__bg {
	    position: relative;
	    width: 100%;
	    height: 100%;
	    background: rgba(0,0, 0, .5);
	    z-index: 1;
	  }

	  &__container {
	    position: absolute;
	    z-index: 2;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	  }
	  &__window {
	  	position: relative;
	  	width: 900px;
	  	margin: auto;

			box-sizing: border-box !important;
	    padding: 0;
	    
	    text-align: left;
	    overflow: visible;
	    animation-name: scale-in;
	  	animation-duration: .4s;
	  }
	}

	@media(max-width: 1023px) {
		.trending-articles-pop {
			display: none;
		}
	}

	@media(max-width: 767px) {
	  .left-content {
	    width: 100%;
	    margin-right: 0;

	    h1 {
	      font-size: 32px;
	    }
	  }
	  .right-content {
	    width: 100%;
	    margin-top: 40px;
	  }
	  .trending-articles {
	  	.card {
    		width: 48%;
    	}
	  }
	  .sticky-bar {
      .flex-wrap {
	      display: block;
	      h3 {
	        width: 100%;
	        text-align: center;
	        font-size: 20px;
	      }
	      .button {
	        display: block;
	        margin: 10px auto;
	      }
      }
    }
	}
	@media(max-width: 480px) {
		.trending-articles {
	  	.card {
    		width: 100%;
    	}
	  }
	}
}

.disclosureHeader {
    background: #3d60ac;
    p {
        font-size: 0.9em;
        color: #fff;
        text-align: center;
        margin: 0;
    }
}

.disclosureFooter p {
    font-size: 0.9em;
}

header.landerAdvert {
    background: #fff;
    border-bottom: 1px solid #efefef;
    
    .container {
        max-width: 1080px;
    }
}