.styles {
	width: 100%;
	background: #eee;
	position: relative;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 3px;

	.style-picker {
		z-index: 2;
		display: block;
		line-height: 0;
		position: absolute;
		bottom: 20px;
		right: 20px;

		> div {
			width: 20px;
			height: 20px;
			border: 1px solid #000;
			display: inline-block;
			margin: 0 5px 0 0;
			border-radius: 20px;
			cursor: pointer;
		}
	}

	> div + div {
		display: none;
	}

	i {
		font-size: 14rem;
		color: #fff;
	}
}

.no-bg {
    background: none;
}

.bg-grey {
    background: rgba(0, 0, 0, .05);
}

.product-list {
	display: flex;
	flex-flow: wrap;
	margin: 0 -20px;

	&, > li {
		list-style: none;
		padding: 0;
	}

	> li {
		flex: 1 1 300px;

		&:not(:empty) {
			margin: 20px;
		}
	}

	h4 {
		margin: 10px 0 0 0;
		padding-right: 10px;
	}

	p {
		line-height: 1;
		margin: 10px 0 0 0;
	}

	.product-description p {
		font-size: 1.1rem;
	}

	.snipcart-add-item {
		-webkit-appearance: none;
		color: #222;
		text-decoration: none;
		border: 0;
		background: none;
		font-weight: bold;
		font-size: 1.2em;
		padding: 10px 15px 15px;
		float: right;
		cursor: pointer;
		line-height: 1;
		margin-top: 10px;

		&:hover {
			background: #f5f5f5;
			color: #000;
		}
	}
}

.flex-wrap {
	display: flex;
	justify-content: space-between;
}


.product {

	h1 {
    font-weight: 700;
    font-size: 3.2em;    
  }

	h2 {
    font-weight: 700;
  }

  .text-center {
    text-align: center;
  }

  .blue-text {
    color: blue;
  }

	&__hero {
    padding: 40px 0;

    .flex-wrap {
      flex-direction: row-reverse;
    }
    
	  h3 {
	    font-weight: 500;
	  }
		.image-wrap {
		  width: 45%;
		}
		.product-details {
		 	width: 45%;
		}
		ul {
			list-style: none;
			margin-left: 0;
			padding-left: 0;
			
			li {
			  display: flex;
    		align-items: center;
			}

			li:before {
    	  left: -5px;
				color: transparent;
				content: " ";
				font-size: 0;
				padding: 10px;
				margin-right: 10px;
				background-color: #6462ff;
				-webkit-mask-image: url(../images/check-circle-solid.svg);
				-webkit-mask-size: cover;
			}
		}
	}
	&__logos {
	  padding: 20px 0;
	  border-top: 1px solid rgba(0, 0, 0, .2);
	  border-bottom: 1px solid rgba(0, 0, 0, .2);
	}
	&__services {
	  padding: 20px 0;
	  background: #31607d;

	  .service {
	    display: flex;
	    align-items: center;
	    img {
	      width: 30%;
	    }
	    div {
	      width: 65%;

	      h3 {
	        font-size: 20px;
	        color: #fff;
	      }
	      p {
	        font-size: 1rem;
	        color: #fff;
	      }
	    }
	  }
	}
	&__property {
	  padding: 50px 0;

	  .flex-wrap {
	    align-items: center;
	  }

		br {
		  display: block;
		  content: "";
		  margin: 20px 0;
		}
		.text-wrap {
		  width: 47%;
		}
		.image-wrap {
		  width: 47%;
          text-align: center;

		  img {
		    width: 100%;
            max-width: 400px;
		  }
		}
	}
	&__video {
	  padding: 50px 0;
	  text-align: center;

	  h2 {
	    max-width: 600px;
	    margin: auto;
	  }
	  p {
	    max-width: 700px;
	    margin: 20px auto;
	  }
	  video {
	    width: 100%;
	    max-width: 700px;
	    margin: auto;
	  }
	}
	&__howtouse {
	  padding: 50px 0;
	  text-align: center;
	  h2 {
	    max-width: 600px;
	    margin: auto;
	  }
	  .title-border {
	    width: 100px;
	    height: 1px;
	    margin: 15px auto 30px;
	  }
      img {
        max-width: 300px;
      }    
	  &__item {
	    padding: 0 15px;

	    h4 {
	      font-size: 1.6rem;
	    }
	  }
	}
	&__imageshow {
	  width: 100%;
	  padding: 0;
	  margin: 0;
		display: flex;

		.img-item {
		  width: 25%;
		  margin: 0;

		  img {
		    width: 100%;
		    height: 100%;
		  	margin: 0;
		  }
		}
	}
	&__features {
	  position: relatve;
	  padding: 50px 0;

	  h2 {
	    text-align: center;
	    margin-bottom: 40px;
	  }

	  .feature-image {
	  	position: absolute;
	  	top: 50%;
	  	left: 50%;
	  	transform: translate(-50%, -50%);
	  }
	  
	  &__list {
	    width: 32%;
	    position: relative;

	    li {
	      position: relative;
	      list-style: none;
	      margin-left: 10px;
	      padding-left: 20px;

	    	&:before {
	    	  position: absolute;
	    	  top: 3px;
	    	  left: -5px;
					color: transparent;
					content: " ";
					font-size: 0;
					padding: 10px;
					margin-right: 10px;
					background-color: #6462ff;
					-webkit-mask-image: url(../images/check-circle-solid.svg);
					-webkit-mask-size: cover;
    		}
	    }
	  }
	}
	&__reviews {
	  padding: 50px 0;
	  background: rgba(0, 0, 0, .05);

	  .review-card {
	    width: 48%;
	    margin-top: 50px;

	    img {
	      width: 100%;
	    }

	    .h3 {
	      font-weight: 700;
	    }

	    p {
	      padding: 20px 10px;
	      background: white;
	      border-radius: 10px;
	      font-style: italic;
	      font-size: 20px;
	      color: #333;
	    }
	  }
	}
	&__specialOff {
		padding: 50px 0;

		h2 {
		  color: red;
		  text-transform: uppercase;
		}

		h3 {
		  font-size: 24px;
		  font-weight: 700;
		  max-width: 500px;
		  margin: 20px auto;
		}

		.bottom-image {
	    margin-top: 30px;
	  }

	  .ip-countryflag {
	    margin-left: 10px;
	  }

		.off-item {
		  padding: 10px;
		  display: flex;
		  align-items: center;
		  margin: 0 auto 10px;
		  border-radius: 8px;
		  background: rgb(255,255,232);
		  border: 1px solid rgb(127,127,127);
		  max-width: 700px;

		  &:hover {
		    cursor: pointer;
        animation: wobble 1s 1;  
        text-decoration: none;
		  }

		  .red-text {
		    color: red;
		    text-transform: uppercase;
		    font-weight: 600;
		  }

		  .image-wrap {
		    width: 25%;
		    text-align: center;

		    img {
		      height: 80px;
		      aspect-ratio: auto 80 / 80;
		    }
		  }
		  .text-wrap {
		    width: 50%;
		    label {
		      font-size: 14px;
		    }
		    p {
			    font-size: 1.4rem;
			    line-height: 1.6;
			    font-weight: bold;
			    margin: 5px 0 0;
                color: #000;
			  }
		  }
		  .off-wrap {
		    width: 15%;

		    .was {
		      text-decoration: line-through;
		    }

		    p {
		      font-size: 14px;
		      margin: 5px 0;
		    }
		  }
		}
		.popular {
			border: 2px dashed rgb(0, 0, 0);
			background: rgb(255,255,94);
		}
	}
	&__testimonials {
	  padding: 100px 0;
	  background: rgba(0, 0, 0, .05);

	  .stars {
	    width: 200px;
	    margin: 20px auto;

	    svg {
	      width: 100%;
	    }
	  }

	  h3 {
	    font-size: 24px;
	    margin-bottom: 30px;
	  }

	  .card-list {
	    display: flex;
	    justify-content: space-between;
	  }

	  .card {
	    width: calc(31% - 40px);
	    padding: 20px;
	    border-radius: 10px;
	    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
	    background: #fff;

	    .flex-wrap {
	      border-bottom: 1px solid rgba(0, 0, 0, .2);
	      padding-bottom: 5px;

	      .left {
	        display: flex;
	        align-items: flex-start;
	        color: #333;
	        img {
		        max-width: 58px;
		        border-radius: 5px;
		        margin-right: 10px;
		      }
	      }
	      .stars {
	        width: 100px;
	        float: right;
	        border-bottom: 0;
	        padding-bottom: 0;
	      }
	    }
	    .description {
	      padding-top: 15px;
	      font-size: 20px;
	      font-style: Italic;
	      color: rgb(51,51,51);
	    }
	  }
	}
	&__faqs {
	  padding: 70px 0;
	  background: rgba(0, 0, 0, .05);

	  h2 {
	    margin-bottom: 30px;
	  }

	  .faq {
	    margin-bottom: 8px;
	  	.question {
	  	  display: flex;
	  	  align-items: center;
	  	  justify-content: space-between;
	  	  border-radius: 5px;
	  	  box-shadow: 0 0 8px rgba(0, 0, 0, .2);
	  	  padding: 10px 20px;
	  	  background: #fff;
	  	  color: #333;

	  	  h4 {
	  	    font-weight: 400;
	  	    line-height: 1;
	  	    margin: 10px 0;
	  	  }

	  	  .question-text {
	  	    width: 80%;
	  	  }

	  	  question-symbole {
	  	    width: 20px;
	  	  }

	  	  &:hover {
	  	    cursor: pointer;
	  	    color: #656565;
	  	  }
	  	  .rotate {
	  	    transform: rotate(45deg);
	  	  }
	  	}
	  	.answer {
	  	  padding: 20px;
	  	  background: white;
	  	  height: auto;
	  	  opacity: 1;
	  	  color: #333;
	  	  transition: opacity 1s ease-out;
	  	}
	  	.hidden {
	  	  opacity: 0;
	  	  height: 0;
	  	  padding: 0;
	  	  overflow: hidden;
	  	}
	  }
	}
}

.special-off-popup {
  position: fixed;
  display: none;
  width: 100%;
  height: 100vh;
  top: 0;

  .special-off-popup-bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0,0, 0, .5);
    z-index: 1;
  }

  .popup-container {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
 
  .popup-content {
  	position: relative;
  	width: 650px;
  	margin: auto;

		box-sizing: border-box !important;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 20px 15px;
    
    text-align: left;
    overflow: visible;
    border: 4px dashed rgb(51,51,51) !important;
    animation-name: scale-in;
  	animation-duration: .4s;

    .close-button {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 1px solid white;
      color: white;
      background: black;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      z-index: 4;
      &:hover {
        cursor: pointer;
      }
    }

    h2 {
      text-align: center;
      color: red;
      margin-bottom: 20px;
    }

    .image-wrap {
      width: 48%;
      text-align: center;

      img:first-child {
        border: 1px solid gray;
        width: 60%;
      }
      img:last-child {
        width: 60%;
        margin-top: 10px;
      }
    }

    .text-wrap {
      width: 48%;

      h3 {
        color: red;
        margin: 5px 0;
        font-size: 1.4rem;
      }

      h4 {
        margin: 10px 0;
        font-weight: 700;
        font-size: 22px;
      }

      svg {
        width: 10px;
        fill: blue;
        margin-right: 15px;
      }
      ul {
        padding-left: 0;
      }
      li {
        list-style: none;
        margin-left: 0;
      }
      .regular-price {
        font-style: Italic;
        text-decoration: line-through;
      }

      p {
        margin: 0;
        font-size: 16px;
      }
    }
    .buttons-wrap {
      margin-top: 30px;

      a {
        &:hover {
          text-decoration: none;
        }
      }

      .button-order {
        display: block;
        width: calc(100% - 20px);
        font-size: 20px;
        font-weight: 700;
        color: white;
        text-transform: uppercase;
        text-align: center;
        padding: 12px 10px;
        border-radius: 8px;
        background: red;
        &:hover {
          cursor: pointer;
		    	animation: wobble 1s 1;
        }
      }
      .button-no {
      	display: block;
        width: calc(100% - 20px);
        font-size: 20px;
        font-weight: 700;
        color: gray;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
        border-radius: 8px;
        border: 2px solid gray;
        margin-top: 10px;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.exitpop {
  position: fixed;
  visibility: hidden;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;

  &__bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0,0, 0, .5);
    z-index: 1;
  }

  &__container {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__window {
  	position: relative;
  	width: 400px;
  	margin: auto;

		box-sizing: border-box !important;
    padding: 0;
    
    text-align: left;
    overflow: visible;
    animation-name: scale-in;
  	animation-duration: .4s;
  }
  &__header-bar {
    width: 100%;
    position: relative;
    background: red;
    padding: 10px 20px;

    p {
      margin: 0;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
    }
    .close {
      position: absolute;
      z-index: 10;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 1px solid;
      font-size: 18px;
      color: white;
      line-height: 1;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
  &__body {
    width: 100%;
    text-align: center;
    padding: 20px;
    background: #ffffff;

    h3 {
      margin: 0;
      font-size: 30px;
      text-transform: uppercase;
    }
    h4 {
      text-transform: uppercase;
      margin: 5px 0;
      font-weight: 400;
    }

    p {
      margin: 15px 0 10px;
    }
    button {
      width: 100%;
      margin: 10px 0;
      background: red;
      color: #ffffff;
      text-transform: uppercase;
      border: 0;
      border-radius: 3px;
      padding: 10px 20px;
      font-weight: 700;
    }
    .descripton {
    	p {
	      font-size: 14px;
	      line-height: 1.2;
	      margin: 0;
	    }
    }
  }
}

.successPop {
  position: fixed;
  visibility: hidden;
  z-index: 101;
  width: 100%;
  height: 100%;
  top: 0;

  &__bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0,0, 0, .5);
    z-index: 1;
  }

  &__container {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__window {
  	position: relative;
  	width: 450px;
  	margin: auto;
  	background: white;
  	padding: 20px;

		box-sizing: border-box !important;
		border-radius: 5px;
    
    text-align: center;
    overflow: visible;
    animation-name: scale-in;
  	animation-duration: .4s;

  	h2 {
  	  text-transform: uppercase;
  	}

  	.close {
      position: absolute;
      z-index: 10;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 1px solid;
      font-size: 18px;
      color: white;
      line-height: 1;
      text-align: center;
      background: rgba(0, 0, 0, .5);

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media(max-width: 1024px) {
	.exitpop {
		opacity: 0;
    animation-name: visible-show;
  	animation-delay: 30s;
  	animation-fill-mode: forwards;

	  &__container {
	    position: absolute;
	    z-index: 2;
	    bottom: 0px;
	    top: auto;
	    left: 50%;
	    transform: translateX(-50%);
	  }
	  &__bg {
	    opacity: 0;
	    animation-name: slideup;
	  	animation-delay: 30s;
	  	animation-fill-mode: forwards;
	  }
	  &__window {
	  	opacity: 0;
	  	animation-name: slideup;
	  	animation-delay: 30s;
	  	animation-fill-mode: forwards;
	  }
	}
}

@media(max-width: 767px) {
	.flex-wrap {
	  flex-wrap: wrap;
	}
	.product {
		&__hero {
		  padding: 20px 0 60px 0;
			.image-wrap {
			  width: 100%;
			}
			.product-details {
			 	width: 100%;

			 	h1 {
			 	  margin-top: 10px;
                  font-size: 2.8em;    
			 	}
			}
		}
		&__logos {
		  img {
		    margin: 10px auto;
		  }
		}
		&__property {
			.flex-wrap {
			  flex-direction: column-reverse;
			}
			.text-wrap {
			  width: 100%;
			}
			.image-wrap {
			  width: 100%;
			  img {
			    margin-bottom: 30px;
			  }
			}
            h2 {
              font-size: 2.0em;    
            }
		}
		&__reviews {
			.review-card {
			  width: 100%;
			}
		}
		&__imageshow {
		  flex-wrap: wrap;

		  .img-item {
		    width: 50%;
		  }
		}
		&__features {
		  .feature-image {
		    position: relative;
		    width: 100%;
		  }
		  &__list {
		    width: 100%;
		  }
		}
		&__testimonials {
		  padding: 50px 0;
		  .flex-wrap {
		    flex-wrap: nowrap;
		  }
		  .card-list {
		    flex-wrap: wrap;

		    .card {
		      width: 100%;
		      margin-bottom: 30px;
		    }
		  }
		}
		&__faqs {
		  padding: 50px 0;
		}
	}
	.special-off-popup {
	  .popup-container {
	    position: absolute;
	    z-index: 2;
	    left: 50%;
	    width: 100%;
	    top: 50%;
	    transform: translate(-50%, -50%);
	  }
	 
	  .popup-content {
	  	position: relative;
	  	width: 90%;
	  	max-width: 90%;
	  	margin: auto;
	  	overflow: scroll;
	  	height: 90vh;

	    .image-wrap {
	      width: 100%;
	      
	    }
	    .text-wrap {
	      width: 100%;
	    }
	    .close-button {
	      top: 5px;
	      right: 5px;
	    }
	  }
	}
}

@media(max-width: 520px) {
	.product {
		&__specialOff {
			.off-item {
				.image-wrap {
			    width: 20%;
			    text-align: center;

			    img {
			    	height: 60px;
			      aspect-ratio: auto 1;
			    }
			  }
			  .text-wrap {
			    width: 55%;
			    padding: 0px 10px 0 5px;
			    label {
			      font-size: 14px;
			    }
			    p {
				    font-size: 18px;
				  }
			  }
			  .off-wrap {
			    width: 25%;
			  }
			}
		}
		&__faqs {
			.question {
			  h4 {
			    font-size: 20px;
			  }
			}
		}
	}
    .special-off-popup .popup-content {
        .image-wrap {
          width: 100%;
          text-align: center;

          img:first-child {
            border: 1px solid gray;
            width: 30%;
          }
          img:last-child {
            width: 50%;
            margin-top: 10px;
          }
        }
    }
    .special-off-popup .popup-content h2 {
        margin-bottom: 10px;
        font-size: 1.2em;
    }
    ul {
        margin: 0 10px;
    }
}

@media(max-width: 480px) {
	.exitpop {
		&__container {
		  width: calc(100% - 40px);
	    padding: 0;
	    left: 0;
	    transform: none;
		}
		&__window {
		  width: 100%;
		}
	}
	.special-off-popup {
	  .popup-container {
	    .popup-content {
	    	height: auto;
	    }
	  }
	}
	.successpop {
	  &__container {
	    width: calc(100% - 40px);
	    left: 0;
	    transform: (0, -50%);
	  }
	  &__window {
	    width: 100%;
	  }
	}
}
	
@media(max-width: 375px) {
	.special-off-popup {
	  .popup-container {
	    height: calc(100vh - 100px);
	    top: 0;
	    padding: 30px 0;
	    transform: translate(-50%, 0);
	    overflow-y: scroll;
	  }
	}
}